@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Prompt:wght@100;200;300;400;500;600;700;800;900&display=swap");
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
  border-width: 2px !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
  border-width: 2px !important;
}

.MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiButton-containedPrimary {
  background-color: #00a950 !important;
  font-weight: 700 !important;
}

.MuiButton-outlinedPrimary {
  border: 1px solid rgba(0, 169, 80, 0.5) !important;
  color: #00a950 !important;
  font-weight: 700 !important;
}
.MuiButton-outlinedPrimary:hover {
  background-color: rgba(0, 169, 80, 0.04) !important;
}

.MuiCard-root {
  border-radius: 1.5rem !important;
  background: #fff !important;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1) !important;
  padding: 2.5rem;
}
@media (max-width: 768px) {
  .MuiCard-root {
    padding: 1.8rem 1.4rem;
  }
}

.MuiFormLabel-root {
  color: rgba(0, 0, 0, 0.87) !important;
  font-family: Helvetica !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
  margin-bottom: 0.5rem !important;
}

body {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: #eef4f1;
}
body::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 17rem;
  background: linear-gradient(89deg, #00a950 0%, #00763d 63.58%, #064426 99.22%);
  top: 0;
  left: 0;
  z-index: -1;
}
body #root {
  width: 100%;
  height: 100%;
}
body #root .containerQR {
  width: 100%;
  padding: 0 15rem;
  transition: 0.3s ease-in-out;
  padding-bottom: 2.5rem;
}
@media (max-width: 1440px) {
  body #root .containerQR {
    padding: 0 8rem;
    margin-top: -1rem;
    padding-bottom: 2.5rem;
  }
}
@media (max-width: 1200px) {
  body #root .containerQR {
    padding: 0 4rem;
    margin-top: -1rem;
    padding-bottom: 2.5rem;
  }
}
@media (max-width: 768px) {
  body #root .containerQR {
    padding: 0 15px;
    padding-bottom: 2.5rem;
  }
}

.containerTitle {
  padding: 4rem 4rem 4rem 4rem;
}
.containerTitle .title {
  color: #fff;
  font-family: "Kanit", sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.containerTitle .description {
  color: #fff;
  font-family: "Kanit", sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 768px) {
  .containerTitle {
    padding: 2rem 25px 2rem 25px;
  }
}

.box-QR {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-self: center;
  margin-bottom: 20px;
}
.box-QR svg {
  width: 100%;
  height: 100%;
  max-width: 350px;
  max-height: 350px;
}
.box-QR img {
  width: 100%;
  height: 100%;
  max-width: 375px;
  max-height: 375px;
}

.text-pls-gen {
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
  font-family: "Kanit", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.5rem */
}

.text-booth {
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  font-family: "Kanit", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 157%; /* 1.57rem */
  letter-spacing: 0.00625rem;
}

.section-left-gen-QR,
.section-right-gen-QR {
  padding: 0 20px;
  transition: 0.3s ease-in-out;
}

#templateQR {
  position: relative;
  background-color: #fff;
  width: 0;
  height: 0;
  padding: 100px;
  display: none;
}
#templateQR .logo {
  width: 100%;
  max-width: 800px;
  margin: auto;
}
#templateQR .logo img {
  width: 100%;
}
#templateQR .text {
  margin: 80px 0 50px 0;
  font-size: 64px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  font-family: "Kanit", sans-serif;
}
#templateQR .text.wisdom {
  margin: 50px 0 30px 0;
}
#templateQR .border-qr {
  border: 8px solid #00a950;
  border-radius: 20px;
  width: 900px;
  height: 950px;
  margin: auto;
  position: relative;
}
#templateQR .border-qr .qr {
  width: 800px;
  height: 800px;
  padding: 50px;
}
#templateQR .border-qr .qr .img-qr {
  width: 100%;
  height: 100%;
}
#templateQR .border-qr .empName {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 45px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-family: "Kanit", sans-serif;
}
#templateQR .border-qr .empName .text-sm {
  font-size: 36px;
}
#templateQR .border-qr.wisdom {
  border-color: #651c2d;
}
#templateQR .border-qr.not-template {
  border: none;
  width: 1200px;
  height: 1200px;
}
#templateQR .border-qr.not-template .qr {
  width: 1100px;
  height: 1100px;
  padding: 50px;
}
#templateQR .border-qr.not-template .empName {
  position: relative;
  font-size: 45px;
  font-weight: 600;
  text-align: center;
  width: 100%;
  font-family: "Kanit", sans-serif;
}
#templateQR .border-qr.not-template .empName .text-sm {
  font-size: 36px;
}
#templateQR[data-channel=Spoke], #templateQR[data-channel=FXBooth2] {
  padding: 0px;
}
#templateQR .template-fxbooth {
  width: 1241px;
  height: 1754px;
  position: relative;
  background-image: url("../images/bg-fxbooth.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
#templateQR .template-fxbooth .qr {
  width: 555px;
  height: 555px;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 26%);
}
#templateQR .template-fxbooth .qr .img-qr {
  width: 100%;
  height: 100%;
}
#templateQR .template-fxbooth .empName {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  color: #413031;
  font-family: "Kanit", sans-serif;
  font-size: 40px;
  font-weight: 400;
  line-height: normal;
}
#templateQR .template-fxbooth .empName.bg-empName {
  color: #fff !important;
  background-color: #0b7f4f;
  padding: 8px 30px;
  border-radius: 50px;
  width: -moz-fit-content !important;
  width: fit-content !important;
  margin-top: 10px;
  white-space: nowrap;
}
#templateQR .template-spoke {
  width: 1241px;
  height: 1754px;
  position: relative;
  background-image: url("../images/bg-spoke.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
#templateQR .template-spoke .qr {
  width: 500px;
  height: 500px;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 17%);
}
#templateQR .template-spoke .qr .img-qr {
  width: 100%;
  height: 100%;
}
#templateQR .template-spoke .empName {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -70%);
  width: 100%;
  text-align: center;
  color: #413031;
  font-family: "Kanit", sans-serif;
  font-size: 46px;
  font-weight: 400;
  line-height: normal;
}
#templateQR .scan-me {
  width: 100%;
  max-width: 900px;
  margin: 15px auto;
}
#templateQR .scan-me img {
  width: 100%;
}

@media (max-width: 959px) {
  .section-left-gen-QR {
    padding: 0 0;
  }
  .section-right-gen-QR {
    padding: 20px 0;
  }
}/*# sourceMappingURL=app.css.map */