body {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: #eef4f1;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 17rem;
    background: linear-gradient(
      89deg,
      #00a950 0%,
      #00763d 63.58%,
      #064426 99.22%
    );
    top: 0;
    left: 0;
    z-index: -1;
  }

  #root {
    width: 100%;
    height: 100%;

    .containerQR {
      width: 100%;
      // height: 100%;
      padding: 0 15rem;
      transition: 0.3s ease-in-out;
      padding-bottom: 2.5rem;

      @media (max-width: 1440px) {
        padding: 0 8rem;
        margin-top: -1rem;
        padding-bottom: 2.5rem;
      }

      @media (max-width: 1200px) {
        padding: 0 4rem;
        margin-top: -1rem;
        padding-bottom: 2.5rem;
      }

      @media (max-width: 768px) {
        padding: 0 15px;
        padding-bottom: 2.5rem;
      }
    }
  }
}

.containerTitle {
  padding: 4rem 4rem 4rem 4rem;

  .title {
    color: #fff;
    font-family: 'Kanit', sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .description {
    color: #fff;
    font-family: 'Kanit', sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media (max-width: 768px) {
    padding: 2rem 25px 2rem 25px;
  }
}

.box-QR {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-self: center;
  margin-bottom: 20px;

  svg {
    width: 100%;
    height: 100%;
    max-width: 350px;
    max-height: 350px;
  }

  img {
    width: 100%;
    height: 100%;
    max-width: 375px;
    max-height: 375px;
  }
}

.text-pls-gen {
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
  font-family: 'Kanit', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.5rem */
}

.text-booth {
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  font-family: 'Kanit', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 157%; /* 1.57rem */
  letter-spacing: 0.00625rem;
}

.section-left-gen-QR,
.section-right-gen-QR {
  padding: 0 20px;
  transition: 0.3s ease-in-out;
}

#templateQR {
  position: relative;
  background-color: #fff;
  width: 0;
  height: 0;
  padding: 100px;
  display: none;

  .logo {
    width: 100%;
    max-width: 800px;
    margin: auto;

    img {
      width: 100%;
    }
  }

  .text {
    margin: 80px 0 50px 0;
    // font-size: 64px;
    font-size: 64px;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    font-family: 'Kanit', sans-serif;

    &.wisdom {
      margin: 50px 0 30px 0;
    }
  }

  .border-qr {
    border: 8px solid #00a950;
    border-radius: 20px;
    width: 900px;
    height: 950px;
    margin: auto;
    position: relative;

    .qr {
      width: 800px;
      height: 800px;
      padding: 50px;

      .img-qr {
        width: 100%;
        height: 100%;
      }
    }

    .empName {
      position: absolute;
      bottom: 15px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 45px;
      width: 100%;
      text-align: center;
      font-weight: 600;
      font-family: 'Kanit', sans-serif;

      .text-sm {
        font-size: 36px;
      }
    }

    &.wisdom {
      border-color: #651c2d;
    }

    &.not-template {
      border: none;
      width: 1200px;
      height: 1200px;

      .qr {
        width: 1100px;
        height: 1100px;
        padding: 50px;
      }

      .empName {
        position: relative;
        font-size: 45px;
        font-weight: 600;
        text-align: center;
        width: 100%;
        font-family: 'Kanit', sans-serif;

        .text-sm {
          font-size: 36px;
        }
      }
    }
  }

  &[data-channel='Spoke'],
  &[data-channel='FXBooth2'] {
    padding: 0px;
  }

  .template-fxbooth {
    width: 1241px;
    height: 1754px;
    position: relative;
    background-image: url('../images/bg-fxbooth.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .qr {
      width: 555px;
      height: 555px;
      padding: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 26%);

      .img-qr {
        width: 100%;
        height: 100%;
      }
    }

    .empName {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      text-align: center;
      color: #413031;
      font-family: 'Kanit', sans-serif;
      font-size: 40px;
      font-weight: 400;
      line-height: normal;

      &.bg-empName {
        color: #fff !important;
        background-color: #0b7f4f;
        padding: 8px 30px;
        border-radius: 50px;
        width: fit-content !important;
        margin-top: 10px;
        white-space: nowrap;
      }
    }
  }

  .template-spoke {
    width: 1241px;
    height: 1754px;
    position: relative;
    background-image: url('../images/bg-spoke.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .qr {
      width: 500px;
      height: 500px;
      padding: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 17%);

      .img-qr {
        width: 100%;
        height: 100%;
      }
    }

    .empName {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -70%);
      width: 100%;
      text-align: center;
      color: #413031;
      font-family: 'Kanit', sans-serif;
      font-size: 46px;
      font-weight: 400;
      line-height: normal;
    }
  }

  .scan-me {
    width: 100%;
    max-width: 900px;
    margin: 15px auto;

    img {
      width: 100%;
    }
  }
}

@media (max-width: 959px) {
  .section-left-gen-QR {
    padding: 0 0;
  }

  .section-right-gen-QR {
    padding: 20px 0;
  }
}
